// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'name',
    label: '',
    Object: 'value',
    width: '200'
  }, {
    prop: 'image',
    label: '',
    img: true,
    height: '100px',
    width: '120'
  }, {
    prop: 'activity_time',
    label: '',
    Object: 'value',
    width: '160'
  }, {
    prop: 'activity_type',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'homepage_display',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'online',
    label: '',
    Object: 'value',
    width: '120'
  }]
}
