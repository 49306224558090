<template>
  <div>
     <el-card>
      <search-form ref="searchForm" :topVisible="true" :tabData="tabData" labelWidth="120px" :searchFormData="searchFormData" @search="search" @tabChange="tabChange">
        <template #topBtn>
          <el-button type="primary" size="small" @click="handleAddActivityClick">{{$t('table.activity.addActivity')}}</el-button>
        </template>
      </search-form>
      <create-table :autoWidth="true" :operateVisible="operateVisible" ref="createTable" :loading="loading" :btnType="[]" :total_count="pageData.total_count ? pageData.total_count : 0" :current_page.sync="pageData.page" :papeSize.sync="pageData.per_page" @selectionChange="selectionChange" :topButtonVisible="true" :tableData="tableData" :tableDataItem="tableDataItem" @operate="handleClick" :pageVisible="true" :pageData="pageData" :checkBoxVisible="dropDataItem.length > 0" @handleSort="handleSort" @onRowDropEnd="handleCreateTableRowDropEnd" @onSwitchChange="handleCreateTableSwitchChange">
        <template #topButton>
          <span v-if="dropDataItem.length > 0" class="marginRight20 spanminWith">{{`${$t('text.selected')}：${selectionData.length}`}}</span>
          <!-- <dropListCollect :dropDisabled="selectionData.length < 1" :dropDataItem="dropDataItem" class="marginRight20" @chooseDropItem="chooseDropItem"></dropListCollect> -->
          <allButton :buttonDisabled="selectionData.length < 1" :buttonDataItem="dropDataItem" class="marginRight20" @chooseDropItem="chooseDropItem"></allButton>
        </template>
         <template #rightBtn="scope">
            <el-button class="margintop10" key="view" type="primary" size="mini" @click="handleClick({action: 'view'}, scope.row)">{{$t('button.view')}}</el-button>
            <el-button class="margintop10" v-for="(item, index) in scope.row.member_actions.default_actions" :key="'default_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleClick(item, scope.row)">{{item.name}}</el-button>
            <el-button class="margintop10" v-for="(item, index) in scope.row.member_actions.custom_actions" :key="'custom_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleCusClick(item, scope.row)">{{item.name}}</el-button>
        </template>
      </create-table>
    </el-card>
    <router-view/>
    <dialogEffect :dateArray="dateArray" :opereteDate="true" v-if="dialogVisible" :dialogVisible.sync="dialogVisible" :data="dialogData" @doNextConfirm="doNextConfirm"></dialogEffect>
  </div>
</template>

<script>
import { getShopActivitiesSetup, getShopActivities, putShopActivitiesIdRank, deleteShopActivities, putShopActivities } from '@/services/promotion'
import loading from '@/utils/loading.json'
import jsonData from './jsonData.js'
import allButton from '@/components/allButton'
import searchForm from '@/components/searchForm'
import createTable from '@/components/createTable'
import dialogEffect from '@/components/dialogEffect'
import { mixin } from '@/mixins/mixin'
export default {
  name: 'managerUserOrders',
  mixins: [mixin],
  components: {
    searchForm,
    createTable,
    allButton,
    dialogEffect
  },
  data () {
    return {
      tableDataItem: jsonData.tableDataItem,
      tabActivityIndex: 0
    }
  },
  created () {
    this.queryData(getShopActivitiesSetup)
  },
  activated () {
    if (this.isActive) this.queryData(getShopActivitiesSetup)
    this.isActive = true
  },
  mounted () {
    const _this = this
    _this.$refs.createTable.rowDrop()
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = this.$loading(loading) : this.$store.state.app.loading.close()
    },
    // 点击操作按钮
    async handleClick (type, data) {
      const _this = this
      if (type?.action === 'view') {
        this.$router.push({
          name: 'promotionManagerCampaignDetail',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'edit') {
        _this.$router.push({
          name: 'promotionManagerCampaignEditActivity',
          params: {
            id: data.id.value
          }
        })
      } else if (type?.action === 'edit_shop_activity_items') {
        _this.$router.push({
          name: 'promotionManagerCampaignEditActivityProduct',
          params: {
            activityId: data.id.value
          }
        })
      } else if (type?.action === 'destroy') {
        _this.$confirm(_this.$t('table.activity.confirmDeleteActivity'), _this.$t('text.confirmation'), {
          type: 'warning'
        }).then(() => {
          _this.setGlobalLoading(true)
          deleteShopActivities(data.id.value).then(res => {
            _this.setGlobalLoading(false)
            _this.$message({
              type: 'success',
              message: _this.$t('message.operateSuccess')
            })
            _this.search()
          }).catch(() => _this.setGlobalLoading(false))
        }).catch(err => err)
      } else if (type?.action === 'move_top') {
        _this.setGlobalLoading(true)
        putShopActivitiesIdRank(data.id.value).then(() => {
          _this.setGlobalLoading(false)
          _this.search()
        }).catch(() => {
          _this.setGlobalLoading(false)
          _this.search()
        })
      }
    },
    // 查询
    search (data) {
      if (data) this.searchData = data
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page })
      this.loading = true
      getShopActivities(this.searchData).then(res => {
        if (res.data) {
          this.tableData = []
          this.$nextTick(() => {
            this.tableData = res.data.objects
            this.tabData = res.data.scopes
            Object.assign(this.pageData, res.data.pagination)
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleAddActivityClick () {
      const _this = this
      _this.$router.push({
        name: 'promotionManagerCampaignAddActivity'
      })
    },
    handleCreateTableRowDropEnd (ev) {
      const _this = this
      const mainTableData = _this.tableData
      const oldIndex = ev.oldIndex
      const newIndex = ev.newIndex
      const requestParams = {}
      if (newIndex !== mainTableData.length - 1) {
        if (oldIndex - newIndex === 1) {
          requestParams.above_item_id = mainTableData[newIndex].id.value
        } else {
          if (newIndex === 0) {
            requestParams.above_item_id = mainTableData[0].id.value
          } else {
            requestParams.above_item_id = mainTableData[newIndex + 1].id.value
          }
        }
      } else {
        requestParams.below_item_id = mainTableData[mainTableData.length - 1].id.value
      }
      _this.setGlobalLoading(true)
      putShopActivitiesIdRank(mainTableData[oldIndex].id.value, requestParams).then(res => {
        _this.setGlobalLoading(false)
        _this.search()
      }).catch(() => {
        _this.setGlobalLoading(true)
        _this.search()
      })
    },
    handleCreateTableSwitchChange (data) {
      const _this = this
      const { rowData, jsonItemData } = data
      if (jsonItemData.prop === 'homepage_display') {
        _this.setGlobalLoading(true)
        putShopActivities(rowData.id.value, {
          homepage_display: rowData[jsonItemData.prop].value
        }).then(() => {
          _this.setGlobalLoading(false)
          _this.$message({
            type: 'success',
            message: _this.$t('message.operateSuccess')
          })
          _this.search()
        }).catch(() => _this.setGlobalLoading(false))
      } else if (jsonItemData.prop === 'online') {
        _this.setGlobalLoading(true)
        putShopActivities(rowData.id.value, {
          online: rowData[jsonItemData.prop].value
        }).then(() => {
          _this.setGlobalLoading(false)
          _this.$message({
            type: 'success',
            message: _this.$t('message.operateSuccess')
          })
          _this.search()
        }).catch(() => _this.setGlobalLoading(false))
      }
    }
    // tabChange (data) {
    //   this.tabActivityIndex = Number(data)
    // },
    // selectionChange (data) {
    //   const _this = this
    //   const { dropDataItem } = _this
    //   const target = _this.tabData[_this.tabActivityIndex].value
    //   for (let i = 0; i < dropDataItem.length; i++) {
    //     const item = dropDataItem[i]
    //     for (let j = 0; j < item.scopes.length; j++) {
    //       const subItem = dropDataItem[i].scopes[j]
    //       // console.log(subItem)
    //       if(subItem === target) {

    //       }
    //     }
    //   }
    // }
  }
}
</script>

<style lang="scss">

</style>
